import React, { useState, useEffect, useMemo } from "react";
import { AxiosError } from "axios";
import moment from "moment";
import { IShow, IEvent, ShowRunTimeSlotDto, BreedGroupJudgeDto, BreedJudgeDto, IRunningOrder, IRunningOrderDogClass } from "@eagerdog/interfaces";
import { Constants, Deductions } from "@eagerdog/constants";

import { nonLicensed, helperService } from "src/services/helper.service";
import { apiService } from "src/services";

import { toast } from "src/components/Toast/ToastManager";
import StepForm, { Step, useStepForm } from "src/components/StepForm/StepForm";
import { ICheck } from "src/components/Checkbox/Checkbox";
import { IOption } from "src/components/Dropdown/Dropdown";

import ShowTypeTab from "./ShowTypeTab/ShowTypeTab";
import ShowNameTab from "./ShowNameTab/ShowNameTab";
import JudgesTab, { IJudge } from "./JudgesTab/JudgesTab";
import PriceTab, { IPrice } from "./PriceTab/PriceTab";
import WaitlistTab, { IWaitlistItem } from "./WaitlistTab/WaitlistTab";
import TimeslotTab from "./TimeslotTab/TimeslotTab";

import "./ShowForm.scss";

interface IProps {
  onFinish(): void,
  clubId: string | undefined,
  event: IEvent | undefined,
  show?: IShow,
  currency?: string
}

export interface ShowRunTimeSlotDtoId extends ShowRunTimeSlotDto {
  id?: string
}

interface IGroupedLevel {
  sanctioning_club: string,
  show_element: string,
  level: string,
  group_with: string
}

export const groupedRunningOrderLevels:IGroupedLevel[] = [
  // { sanctioning_club: Constants.sanctioning_club.AKC, show_element: Constants.show_type.obedience, level: Constants.dog_class_element_level.preferred_novice, group_with:  Constants.dog_class_element_level.beginner_novice_b },
  { sanctioning_club: Constants.sanctioning_club.AKC, show_element: Constants.show_type.obedience, level: Constants.dog_class_element_level.preferred_open, group_with:  Constants.dog_class_element_level.open_b },
  { sanctioning_club: Constants.sanctioning_club.AKC, show_element: Constants.show_type.obedience, level: Constants.dog_class_element_level.preferred_utility, group_with:  Constants.dog_class_element_level.utility_b }
];

export const isGroupedLevel = (sanctioning_club: string, show_element: string, level: string) => {
  let hasGroupedLevel:IGroupedLevel[] = groupedRunningOrderLevels.filter((gl: IGroupedLevel) => { return gl.sanctioning_club === sanctioning_club && gl.show_element === show_element && gl.level === level });

  return hasGroupedLevel.length > 0 ? hasGroupedLevel : undefined;
}

const ShowForm: React.FC<IProps> = (props) => {
  const [isCreatingShow, setIsCreatingShow] = useState<boolean>(false);
  const { switchToTab, activeTab } = useStepForm();

  let hasTimeslots:string[] = useMemo(() => {
    return [
      Constants.show_type.fast_cat,
      Constants.show_type.fetch
    ];
  }, []);

  const [steps, setSteps] = useState<string[]>(["Show Type", "Show Name and Date", "Judges", "Price", "Waitlist"]);

  // Show Type Tab
  const [showType, setShowType] = useState<IOption>(props.event && props.event.sanctioning_club === Constants.sanctioning_club.UKC ? { value: Constants.show_type.nosework, id: Constants.show_type.nosework } : { value: Constants.show_type.conformation, id: Constants.show_type.conformation });
  const [showElements, setShowElements] = useState<ICheck[]>([]);

  // Show Name Tab
  const [akcNum, setAkcNum] = useState<string>();
  const [showName, setShowName] = useState<string>();
  const [useCloseDate, setUseCloseDate] = useState<boolean>(false);
  const [showDate, setShowDate] = useState<Date>();
  const [closeDate, setCloseDate] = useState<Date>();
  const [isTrailingShow, setIsTrailingShow] = useState<boolean>(false);
  const [previousShow, setPreviousShow] = useState<IShow>();

  // Judges Tab
  const [useSameJudge, setUseSameJudge] = useState<boolean>(true);
  const [judges, setJudges] = useState<IJudge[]>([]);
  const [breedGroups, setBreedGroups] = useState<BreedGroupJudgeDto[]>([]);
  const [bsList, setBsList] = useState<BreedJudgeDto[]>([]);

  // Price Tab
  const [useSamePrice, setUseSamePrice] = useState<boolean>(true);
  const [prices, setPrices] = useState<IPrice[]>([]);
  const [useEarlyBirdPrice, setUseEarlyBirdPrice] = useState<boolean>(false);
  const [earlyBirdEndDate, setEarlyBirdEndDate] = useState<any>();
  const [maxDog, setMaxDog] = useState<string>("");

  // Waitlist Tab
  const [waitlists, setWaitlists] = useState<IWaitlistItem[]>([]);

  // Timeslot Tab
  const [timeslots, setTimeslots] = useState<ShowRunTimeSlotDtoId[]>([]);
  const [originalTimeslots, setOriginalTimeslots] = useState<ShowRunTimeSlotDtoId[]>([]);

  const getShowElements = () => {
    const getJudgeInfoByShowElement = (show_element: string) => {
      if (useSameJudge) {
        if (judges.length > 0) {
          let j:any = { ...judges[0] };
          delete j["judge_element"];

          return j;
        }
      } else {
        let j = judges.filter((j: IJudge) => { return j.judge_element === show_element; });
        return j.length > 0 ? j[0] : { judge_name: "N/A", judge_number: "N/A" };
      }
    }

    const showElementHasPrice = (show_element: string) => {
      let p = prices.filter((_p: IPrice) => { return _p.judge_element === show_element; })
      return p.length > 0 ? p[0] : false;
    }

    const showElementHasEarlyBirdPrice = (show_element: string) => {
      let p = prices.filter((_p: IPrice) => { return (_p.judge_element === show_element) && (_p.early_bird_fee !== undefined); });
      return p.length > 0 ? p[0] : false;
    }

    const showElementHasMaxEntries = (show_element: string) => {
      let w = waitlists.filter((w: IWaitlistItem) => { return w.show_element === show_element && w.max_entries !== undefined; });
      return w.length > 0 ? w[0] : false;
    }

    const showElementHasWaitlist = (show_element: string) => {
      let w = waitlists.filter((w: IWaitlistItem) => { return w.show_element === show_element && w.waitlist_enabled === true; });
      return w.length > 0 ? w[0] : false;
    }

    const showElementHasExercises = (show_element: string) => {
      let s = props.show?.show_elements.filter((s: any) => {return s.level === show_element && s.exercises !== undefined; }) || [];
      return s.length > 0 ? s[0] : false;
    }

    return showElements.filter(e => e.checked).map((s: ICheck) => {
      let _element:any = { show_element: s.label, ...getJudgeInfoByShowElement(s.label) };
      let hasPrice:any = showElementHasPrice(s.label);
      let hasEarlyBird:any = showElementHasEarlyBirdPrice(s.label);
      let hasMaxEntries:any = showElementHasMaxEntries(s.label);
      let hasWaitlist:any = showElementHasWaitlist(s.label);
      let hasExercises:any = showElementHasExercises(s.label);

      if (hasPrice) {
        _element.fee = hasPrice.fee ? Number(hasPrice.fee) : "";
      }

      if (hasEarlyBird) {
        _element.early_bird_fee = Number(hasEarlyBird.early_bird_fee);
      }

      if (hasMaxEntries) {
        _element.max_entries = Number(hasMaxEntries.max_entries);
      }

      if (hasWaitlist) {
        _element.waitlist_enabled = hasWaitlist.waitlist_enabled;
        _element.waitlist_size = Number(hasWaitlist.waitlist_size);
      } else {
        _element.waitlist_enabled = false;
      }

      if (props.event && props.event.sanctioning_club === Constants.sanctioning_club.UKC && (showType.value === Constants.show_type.obedience || showType.value === Constants.show_type.rally_obedience)) {
        if (hasExercises && hasExercises.exercises.length > 0) {
          _element.exercises = hasExercises.exercises;
        } else {
          if (props.event) {
            if (Deductions[props.event.sanctioning_club][showType.value][s.label] !== undefined) {
              let deductionDefinitions:any[] = Deductions[props.event.sanctioning_club][showType.value][s.label]["I"];

              if (deductionDefinitions?.length > 0) {
                _element.exercises = [];

                for (let d in deductionDefinitions) {
                  let exercise:any = {
                    exercise: deductionDefinitions[d].exercise,
                    disabled: showType.value === Constants.show_type.rally_obedience ? true : false,
                  };

                  if (deductionDefinitions[d].sign !== undefined) {
                    exercise.sign = deductionDefinitions[d].sign;
                  }

                  _element.exercises.push(exercise);
                }
              }
            }
          }
        }
      }

      if (
        ((showType.value === Constants.show_type.rally_obedience || showType.value === Constants.show_type.obedience)) || 
        (props.event?.sanctioning_club === Constants.sanctioning_club.AKC && showType.value === Constants.show_type.conformation) || 
        (props.event?.sanctioning_club === Constants.sanctioning_club.AKC && showType.value === Constants.show_type.lure_coursing) ||
        (props.event?.sanctioning_club === Constants.sanctioning_club.UKC && showType.value === Constants.show_type.shed_dog) ||
        //(props.event?.sanctioning_club === Constants.sanctioning_club.UKC && showType.value === Constants.show_type.dock_jumping) ||
        (props.event?.sanctioning_club === Constants.sanctioning_club.AKC && (showType.value === Constants.show_type.conformation || showType.value === Constants.show_type.fetch))
      ) {
        _element.show_element = showType.value;
        _element.level = s.label;
      }

      if ((props.event?.sanctioning_club === Constants.sanctioning_club.UKC && showType.value === Constants.show_type.precision_coursing)) {
        if (nonLicensed.includes(s.label)) {
          _element.show_element = Constants.dog_class_element.conformation_non_licensed;
          _element.level = s.label;
        } else {
          _element.show_element = showType.value;
          _element.level = s.label;
        }
      }

      let obedNonLicensed: string[] = [
        Constants.dog_class_element_level.brace,
        Constants.dog_class_element_level.pairs,
        Constants.dog_class_element_level.precision_heeling,
        Constants.dog_class_element_level.team,
        Constants.dog_class_element_level.veteran
      ];

      if ((props.event?.sanctioning_club === Constants.sanctioning_club.UKC && showType.value === Constants.show_type.obedience)) {
        if (obedNonLicensed.includes(s.label)) {
          _element.show_element = Constants.dog_class_element.performance_non_licensed_obedience;
          _element.level = s.label;
        }
      }

      if (showType.value === Constants.show_type.conformation && nonLicensed.includes(s.label) && s.label !== Constants.dog_class_element.junior_showmanship) {
        _element.show_element = /*props.event?.sanctioning_club === Constants.sanctioning_club.ABI ?  Constants.dog_class_element.conformation : */Constants.dog_class_element.conformation_non_licensed;

        if (props.event?.sanctioning_club === Constants.sanctioning_club.ABI) {
          _element.level = s.label;
        }
      }
      
      /*if (props.event?.sanctioning_club === Constants.sanctioning_club.ABI && showType.value === Constants.show_type.conformation) {
        if (s.label === Constants.dog_class_element_level.abi_brace || s.label === Constants.dog_class_element_level.abi_veteran) {
          _element.show_element = showType.value;
          _element.level = s.label;
        }
      }*/
      
      
      if (props.event?.sanctioning_club !== Constants.sanctioning_club.ABI && showType.value === Constants.show_type.conformation && nonLicensed.includes(s.label) && s.label !== Constants.dog_class_element.junior_showmanship) {
        _element.show_element = Constants.dog_class_element.conformation_non_licensed;
        _element.level = s.label;
      }

      return _element;
    });
  }

  const popToast = (msg: string) => {
    toast.show({
      title: "Create/Edit Show",
      content: msg,
      duration: 10000,
      type: "fail"
    });
  }

  const validateShow = (p: any) => {
    const isJudgeEmpty = (j: any) => {
      return j.judge_name === "" && j.judge_number === "";
    }

    const isPriceEmpty = (p: any) => {
      return isNaN(parseInt(p.fee)) || p.fee === undefined || p.fee === "";
    }

    if (showName === "Select a Show Name") {
      popToast("Please select a show name");
      switchToTab(1);
      return false;
    }

    if (p.show_elements.length === 0) {
      popToast("Please select atleast one element for your show");
      switchToTab(0);
      return false;
    }

    if (hasTimeslots.includes(p.show_type) && p.time_slots && p.time_slots.length === 0) {
      popToast("Please include atleast one timeslot for your show");
      switchToTab(5);
      return false;
    }

    if (p.use_early_bird_fee && moment(p.early_bird_end_date).format("X") > moment(p.show_date).format("X") && !p.trailing_show) {
      popToast("Your early bird end date cannot be after the show start date");
      switchToTab(4);
      return false;
    }

    if (p.trailing_show && previousShow === undefined) {
      popToast("Please select a previous show that your current show is trailing");
      switchToTab(1);
      return false;
    }

    for (let i in p.show_elements) {
      if (isJudgeEmpty(p.show_elements[i])) {
        switchToTab(2);

        if (useSameJudge) {
          popToast("Please enter element judge information for your show");
        } else {
          popToast("Please enter element judge information for all show types");
        }

        return false;
      }

      if (!useSamePrice) {
        if (isPriceEmpty(p.show_elements[i])) {
          switchToTab(3);
          popToast("Please enter a price for all show types");
          return false;
        }
      } else {
        if (isPriceEmpty(p)) {
          switchToTab(3);
          popToast("Please enter a price for your show");
          return false;
        }
      }
    }

    return true;
  }

  const createRunningOrder = () => {
    let _running_order:IRunningOrder[] = [];

    // Using Same Judge
    if (judges.length === 1 && judges[0].judge_element.length === 0) {
      let _running_order_item: IRunningOrder = {
        //type: "dog_class",
        judge_name: judges[0].judge_name,
        judge_number: judges[0].judge_number,
        dog_classes: []
      };

      for (let se in showElements) {
        let dc: IRunningOrderDogClass = {
          type: Constants.running_order_dog_class_type.dog_class,
          show_element: showType.id,
          level: showElements[se].label
        };

        if (props.event) {
          let hasGroupedLevel = isGroupedLevel(props.event.sanctioning_club, showType.id, showElements[se].label);

          if (hasGroupedLevel !== undefined && hasGroupedLevel.length > 0) {
            dc.group_with = hasGroupedLevel[0].group_with;
          }          
        }

        if (showElements[se].checked) {
          _running_order_item.dog_classes.push(dc);
        }
      }

      _running_order.push(_running_order_item);
    } else {
      for(let j in judges) {
        let hasJudge = _running_order.filter((r: IRunningOrder) => { return (r.judge_name === judges[j].judge_name && r.judge_number === judges[j].judge_number); });
        let showElementIsAvailable = showElements.filter((se: any) => { return se.label === judges[j].judge_element && se.checked === true; });

        let dc: IRunningOrderDogClass = {
          type: Constants.running_order_dog_class_type.dog_class,
          show_element: showType.id,
          level: judges[j].judge_element
        };

        if (props.event) {
          let hasGroupedLevel = isGroupedLevel(props.event.sanctioning_club, showType.id, judges[j].judge_element);

          if (hasGroupedLevel !== undefined && hasGroupedLevel.length > 0) {
            dc.group_with = hasGroupedLevel[0].group_with;
          }          
        }

        if (showElementIsAvailable.length > 0) {
          if (hasJudge.length > 0) {
            hasJudge[0].dog_classes.push(dc);
          } else {
            _running_order.push({            
              judge_name: judges[j].judge_name,
              judge_number: judges[j].judge_number,
              dog_classes: [dc],
            });
          }
        }
      }
    }

    return _running_order;
  }

  const updateExistingTimeslots = () => {
    for (let ots in originalTimeslots) {
      // If a timeslot originally existed, and doesn't exist anymore, delete it
      let hasRemovedTimeslot = timeslots.filter((t: ShowRunTimeSlotDtoId) => { return originalTimeslots[ots].id === t.id; });

      if (hasRemovedTimeslot.length === 0) {
        apiService.deleteShowRunTimeSlot((props.event?._id || ""), (props.show?._id || ""), (originalTimeslots[ots].id || "")).catch((e: any) => {
          toast.show({
            title: "Delete Timeslot",
            content: e.response.data.message ? e.response.data.message : "Something went wrong when deleting a timeslot, please contact support",
            duration: 10000,
            errorDetails: e,
            type: "fail",
          });

          return false;
        });
      }
    }

    for (let ts in timeslots) {
      if (timeslots[ts].id !== undefined) {
        // If timeslots already exists, check if changed
        let hasTimeslotChanged = originalTimeslots.filter((t: ShowRunTimeSlotDtoId) => { return (t.id === timeslots[ts].id && (JSON.stringify(t) !== JSON.stringify(timeslots[ts]))); });
        
        // If it's changed, update it
        if (hasTimeslotChanged.length > 0) {
          apiService.updateShowRunTimeSlot((props.event?._id || ""), (props.show?._id || ""), (timeslots[ts].id || ""), timeslots[ts]).catch((e: any) => {
            toast.show({
              title: "Update Timeslot",
              content: e.response.data.message ? e.response.data.message : "Something went wrong when updating a timeslot, please contact support",
              duration: 10000,
              errorDetails: e,
              type: "fail",
            });

            return false;
          });
        }
      } else {
        // If timeslot is new, create it
        apiService.createShowRunTimeSlot((props.event?._id || ""), (props.show?._id || ""), timeslots[ts]).catch((e: any) => {
          toast.show({
            title: "Create Timeslot",
            content: e.response.data.message ? e.response.data.message : "Something went wrong when creating a timeslot, please contact support",
            duration: 10000,
            errorDetails: e,
            type: "fail",
          });

          return false;
        });
      }
    }
  }

  const createShow = () => {
    setIsCreatingShow(true);

    let payload: any = {
      show_name: showName,
      show_type: showType.value,
      show_date: showDate,
      fee: prices.length > 0 ? isNaN(parseInt(prices[0].fee)) ? "" : parseInt(prices[0].fee) : "",
      use_registration_end_date: useCloseDate,
      use_early_bird_fee: useEarlyBirdPrice,
      use_same_fee: useSamePrice,
      show_elements: getShowElements(),
      breed_group_judges: breedGroups.filter((b: BreedGroupJudgeDto) => { return b.judge_name.length > 0 && b.judge_number !== undefined && b.judge_number.length > 0; }),
      breed_judges: bsList
    };

    if (props.event?.sanctioning_club === Constants.sanctioning_club.AKC) {
      payload.event_number = akcNum;
    }

    if (useCloseDate) {
      payload.registration_end_date = closeDate;
    }

    if (isTrailingShow) {
      payload.trailing_show = true;

      if (previousShow) {
        //payload.previous_show_name = previousShow.show_type + " " + previousShow.show_name + " (" + moment(previousShow.show_date).format("MMM Do") + ")";
        payload.previous_show_name = previousShow.show_type + " " + previousShow.show_name;
        payload.show_date = moment(previousShow.show_date).add(1, 'minutes');
      }
    } else {
      payload.trailing_show = false;
    }

    if (useEarlyBirdPrice) {
      payload.early_bird_end_date = earlyBirdEndDate;
    }

    if (maxDog.length > 0) {
      payload.max_dogs = parseInt(maxDog);
    }

    if (useEarlyBirdPrice && prices.length > 0 && prices[0].early_bird_fee !== undefined) {
      payload.early_bird_fee = parseInt(prices[0].early_bird_fee);
    }

    if (hasTimeslots.includes(showType.id) && props.show === undefined) {
      payload.time_slots = timeslots;
    } else if (hasTimeslots.includes(showType.id) && props.show !== undefined) {
      updateExistingTimeslots();
    }

    if ((props.show === undefined || (props.show !== undefined && props.show.rings === undefined)) && props.event && props.event.sanctioning_club === Constants.sanctioning_club.AKC && (showType.value === Constants.show_type.rally_obedience || showType.value === Constants.show_type.obedience)) {
      payload.rings = [];

      payload.rings.push({
        ring: Constants.ring_names.ring_1,
        running_order: createRunningOrder()
      });
    }

    let isValidShow:boolean = validateShow(payload);

    if (props.event && isValidShow) {
      if (props.show) {
        apiService.updateShow(props.event._id, props.show._id, payload).then((response) => {
          if (response._id) {
            props.onFinish();
          }
        }).catch((e: AxiosError) => {
          toast.show({
            title: "Edit show",
            content: "Unable to edit show",
            duration: 10000,
            errorDetails: e,
            type: "fail",
          });
        }).finally(() => {
          setIsCreatingShow(false);
        });
      } else {
        apiService.createShow(props.event._id, payload).then((response) => {
          if (response._id) {
            props.onFinish();
          }
        }).catch((e: AxiosError) => {
          toast.show({
            title: "Create show",
            content: "Unable to create show",
            duration: 10000,
            errorDetails: e,
            type: "fail",
          });
        }).finally(() => {
          setIsCreatingShow(false);
        });
      }
    } else {
      setIsCreatingShow(false);
    }
  }

  const pShowType = helperService.usePrevious(showType);

  useEffect(() => {
    if (showType !== pShowType) {
      if (hasTimeslots.includes(showType.id)) {
        setSteps(["Show Type", "Show Name and Date", "Judge", "Price", "Waitlist", "Run Times"]);
      } else {
        setSteps(["Show Type", "Show Name and Date", "Judge", "Price", "Waitlist"]);
      }
    }
  }, [showType, pShowType, hasTimeslots]);

  return (
    <div className="ShowForm">
      <div className="title">{props.show ? "Edit Show": "Create a Show"}</div>
      <div className="formWrap">
        <StepForm activeTab={activeTab} next={switchToTab} steps={steps} onSubmit={createShow} isLoading={isCreatingShow}>
          <Step>
            <>{props.event && <ShowTypeTab
              event={props.event}
              show={props.show !== undefined ? props.show : undefined}
              onChange={(newShowType: IOption, newShowElements: ICheck[]) => {
                setShowType(newShowType);
                setShowElements(newShowElements);
              }}
            />}</>
          </Step>
          <Step>
            <>{props.event && <ShowNameTab
              event={props.event}
              show={props.show !== undefined ? props.show : undefined}
              showType={showType.id}
              clubId={props.clubId}
              onChange={(_akcNum: string, _showName: string, _showDate: any, _closeDate: any, _useCloseDate: boolean, _isTrailingShow: boolean, _previousShow: IShow) => {
                setAkcNum(_akcNum);
                setShowName(_showName);
                setShowDate(_showDate);
                setCloseDate(_closeDate);
                setUseCloseDate(_useCloseDate);
                setIsTrailingShow(_isTrailingShow);
                setPreviousShow(_previousShow);
              }}
            />}</>
          </Step>
          <Step>
            <>{props.event && <JudgesTab
              event={props.event}
              show={props.show !== undefined ? props.show : undefined}
              showType={showType.id}
              showElements={showElements}
              onChange={(_judges: IJudge[], _useSameJudge: boolean, _breedGroups: BreedGroupJudgeDto[], _breedSpecific: BreedJudgeDto[]) => {
                setJudges(_judges);
                setUseSameJudge(_useSameJudge);
                setBreedGroups(_breedGroups);
                setBsList(_breedSpecific);
              }}
            />}</>
          </Step>
          <Step>
            <>{props.event && <PriceTab
              show={props.show !== undefined ? props.show : undefined}
              currency={props.currency ? props.currency : undefined}
              showType={showType.id}
              showElements={showElements}
              onChange={(_prices: IPrice[], _useSamePrice: boolean, _useEarlyBird: boolean, _earlyBirdEndDate: any, _maxDog: string) => {
                setPrices(_prices);
                setUseSamePrice(_useSamePrice);
                setUseEarlyBirdPrice(_useEarlyBird);
                setEarlyBirdEndDate(_earlyBirdEndDate);
                setMaxDog(_maxDog);
              }}
            />}</>
          </Step>
          <Step>
            <>{props.event && <WaitlistTab
              event={props.event}
              showType={showType.id}
              show={props.show !== undefined ? props.show : undefined}
              showElements={showElements}
              onChange={(newWaitlists: IWaitlistItem[]) => {
                setWaitlists(newWaitlists);
              }}
            />}</>
          </Step>
          <>{(hasTimeslots.includes(showType.id)) && <Step>
            <TimeslotTab
              show={props.show !== undefined ? props.show : undefined}
              onChange={(timeslots: ShowRunTimeSlotDtoId[], originalTimeslots: ShowRunTimeSlotDtoId[]) => {
                setTimeslots(timeslots);
                setOriginalTimeslots(originalTimeslots);
              }}
            />
          </Step>}</>
        </StepForm>
      </div>
    </div>
  );
};

export default ShowForm;